<template>
  <m-page-header title="搜索历史" />
  <div class="view">
    <div class="list-wrap">
      <m-table
        :data="dataList"
        :loading="loading"
        :pagination="{
          total: prams.total,
          pageSize: prams.page_size,
          currentPage: prams.page,
        }"
        @currentChange="handleCurrentChange"
      >
        <template #empty>
          <el-empty description="暂无数据"></el-empty>
        </template>
        <el-table-column label="搜索图片">
          <template #default="goods">
            <div class="goods-wrap">
              <el-image
                style="width: 70px; height: 70px; border-radius: 4px"
                :src="goods.row.image"
                fit="cover"
              >
                <template #error>
                  <div class="image-slot">
                    <img src="@/assets/img/goodserro.png" alt="" />
                  </div>
                </template>
              </el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="IP地址" prop="ip"></el-table-column>
        <el-table-column label="搜索时间" prop="create_time"></el-table-column>
        <el-table-column label="搜索用户">
          <template #default="rows">
            <span v-if="rows.row.user_name">{{ rows.row.user_name }}</span>
            <span v-else>—</span>
          </template>
        </el-table-column>
      </m-table>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { goodsList } from '../api/SearchHistory';
export default {
  name: 'SearchHistory',
  setup() {
    const prams = ref({
      page: 1,
      total: 0,
      page_size: 10,
    });
    const dataList = ref([]);
    const loading = ref(true);
    const getList = async () => {
      const { data } = await goodsList(
        { page: prams.value.page, page_size: prams.value.page_size },
        loading,
      );
      dataList.value = data.list;
      prams.value.total = Number(data.total);
    };
    const handleCurrentChange = val => {
      prams.value.page = val;
      getList();
    };
    return {
      prams,
      dataList,
      getList,
      handleCurrentChange,
      loading,
    };
  },
  mounted() {
    this.getList();
  },
};
</script>

<style scoped lang="scss">
.view {
  padding: 15px;
  .serach_input {
    width: 280px;
  }
  .list-wrap {
    .goods-wrap {
      max-width: 500px;
    }
  }
}
</style>
